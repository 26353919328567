angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.documents', {
            url: '/documents/:type?',
            views: {
                "main": {
                    controller: 'DocumentsCtrl',
                    templateUrl: 'web/main/documents/documents.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Documents'
            }
        });
    })

    .controller('DocumentsCtrl', function ($scope, $rootScope, session, $uibModal, signatureService, userService, $stateParams, $mdSidenav, swalService, addDocumentsCategoryModal, $window) {
        var that = this;

        that.session = session.get();
        that.user = session.get().user;
        that.perfil = that.session.user.id;
        that.global = $rootScope;
        that.numberOfPages = () => Math.ceil(that.filteredArray.length / that.pageSize);
        that.currentPage = 0;
        that.pageSize = 5;
        that.search = {};
        that.loading = true;
        that.type = $stateParams.type;
        that.user = that.session.user;
        userService.getUsuarios().then(response => that.users_empresa = response.data);
        signatureService.getFirmaCategories({ user: that.perfil }).then(response => {
            that.categorias = response.data;
            that.categoriesMap = [];
            that.categorias.map(c => that.categoriesMap[c.id] = c);
        });


        if (!that.type) {
            signatureService.getFirmasStatus().then(r => that.signaturesStatus = r.data);
        }

        that.showFilters = () => $mdSidenav('filters').toggle();

        that.addCategory =
            () => addDocumentsCategoryModal.openModal().result.then(
                () => signatureService.getFirmaCategories({ user: that.perfil }).then(
                    (response) => that.categorias = response.data));




        that.deleteCategory = (id) => swalService.requestSwal('¿Estás seguro?', 'Vas a eliminar esta categoría. Las firmas de esta categoría serán movidas a Principal', 'warning',() => signatureService.deleteFirmaCategories(id))
            .then(
                () => signatureService.getFirmaCategories().then(response => {
                    swalService.basicSwal('¡Correcto!', '¡Se ha eliminado la categoría!', 'success');
                    that.search.category = null;
                    that.categorias = response.data;
                    that.update();
                }),
                response => (response.status === 417) ? swalService.basicSwal('Sin permisos', 'Algún dato no es correcto', 'error') : swalService.basicSwal('Algo ha ido mal', 'Ha habido un error al eliminar la categoría', 'error')
            );


        that.update = function () {
            that.loading = true;
            var params = { emisor: that.perfil };
            if ($stateParams.type) {
                params.tipo = $stateParams.type.charAt(0).toUpperCase() + $stateParams.type.slice(1);
            }
            signatureService.retrieveFirmas(params).then(function (response) {
                that.firmas = response.data;
                that.loading = false;
            });
        };

        $scope.$watch('controller.search.category', () => that.currentPage = 0);
        $scope.$watch('controller.search', () => that.currentPage = 0);
        $scope.$watch('controller.perfil', () => that.update());

    })









    .filter('startFrom', () => {
        return function (input, start) {
            start = +start; //parse to int
            return input.slice(start);
        };
    })
    .filter('filterDocuments', () => {
        return function (documents, search) {
            let documentsFiletered = [];
            if (!search) {
                documentsFiletered = documents;
            } else {
                documents.forEach(element => {
                    if (search.id) {
                        if (search.id != element.id) {
                            return;
                        }
                    }

                    if (search.category) {
                        if (!element.category || element.category.id != search.category) {
                            return;
                        }
                    }

                    if (search.fecha) {
                        let o = moment(element.created_at);

                        if (search.fecha == 'today') {
                            if (moment().add(-1, 'days').isAfter(o)) {
                                return;
                            }
                        }
                        if (search.fecha == 'week') {
                            if (moment().add(-1, 'weeks').isAfter(o)) {
                                return;
                            }
                        }

                        if (search.fecha == 'month') {
                            if (moment().add(-1, 'months').isAfter(o)) {
                                return;
                            }
                        }

                        if (search.fecha == 'select') {
                            if (search.desde) {
                                if (moment(search.desde).isAfter(o)) {
                                    return;
                                }
                            }
                            if (search.hasta) {
                                if (moment(search.hasta).isBefore(o)) {
                                    return;
                                }
                            }
                        }
                    }


                    if (search.subject) {
                        if (element.subject.indexOf(search.subject) == -1) {
                            return;
                        }
                    }

                    if (search.estado) {
                        if (element.status != search.estado) {
                            return;
                        }
                    }

                    if (search.signers) {
                        var c = false;
                        for (var k = 0; k < element.signers.length; k++) {
                            var n = element.signers[k].name.toUpperCase();
                            var e = element.signers[k].signatureEmail.toUpperCase();
                            var f = search.signers.name.toUpperCase();
                            if (n.indexOf(f) !== -1 || e.indexOf(f) !== -1) {
                                c = true;
                            }
                        }
                        if (!c) {
                            return;
                        }
                    }

                    documentsFiletered.push(element);

                });
            }

            return documentsFiletered;

        };
    });
